//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {rzContainer, rzButton, rzDropdown} from 'razlet-ui';
import enFlag from '../assets/img/en.png';
import ruFlag from '../assets/img/ru.png';

export default {
  name: 'main-header',
  components: {
    rzContainer,
    rzDropdown,
    rzButton,
  },
  data() {
    return {
      flags: {
        en: enFlag,
        ru: ruFlag,
      },
      locales: ['en', 'ru'],
      menu: [
        { label: 'О компании', route: 'about' },
        { label: 'Условия оферты', route: 'agreement' },
        { label: 'Частые вопросы', route: 'faq' },
      ],
      isMenu: false,
    };
  },
  methods: {
    changeLocale(locale) {
      this.$local = locale;
    },
    switchMenu() {
      this.isMenu = !this.isMenu;
      if (this.isMenu) {
        const overlay = document.createElement('div');
        overlay.className = 'main-overlay';
        document.getElementsByClassName('header__menu-block')[0].prepend(overlay);
      } else {
        document.getElementsByClassName('main-overlay')[0].remove();
      }
    },
    goTo(route) {
      return route === 'home' ? '/' : `${window.location.origin}/${route}`;
    },
  },
};
